<template>
  <v-expansion-panels flat focusable>
    <v-expansion-panel>
      <v-expansion-panel-header class="accent text-subtitle-1 font-weight-bold pa-3">
        <span> <v-icon class="primary--text">mdi-filter-outline</v-icon> Filtros </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="12" md="2" v-if="P_dataRange" class="mt-4">
            <DataRange :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_dataColuna" class="mt-4">
            <SelectedInicioEntrega :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_dataFrom" class="mt-4">
            <DataFrom :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_dataTo" class="mt-4">
            <DataTo :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_clientes" class="mt-4">
            <Clientes :key="componentKey" />
          </v-col>

          <v-col cols="12" md="2" v-if="P_titulo" class="mt-4">
            <Titulo :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_status && P_statusArray" class="mt-4">
            <Status :key="componentKey" :campos="P_statusArray" />
          </v-col>

          <v-col cols="12" md="2" v-if="P_prioridade" class="mt-4">
            <Prioridade :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_clienteServico" class="mt-4">
            <ClienteServico :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_colaborador" class="mt-4">
            <Colaboradores :key="componentKey" />
          </v-col>
          <v-col cols="12" md="2" v-if="P_clientesColaborador" class="mt-4">
            <ClientesColaborador :key="componentKey" />
          </v-col>

          <!-- BOTOES -->
          <v-col cols="12" class="d-flex align-center justify-end">
            <v-btn
              depressed
              small
              color="white"
              class="mr-3"
              @click="resetQuery"
              :disabled="queryEmpty"
            >
              <v-icon left color="buttons2">mdi-reload</v-icon>
              Resetar
            </v-btn>

            <v-btn depressed small color="white" @click="filtrar" :disabled="!valid">
              <v-icon left color="globalBlue">mdi-filter-outline</v-icon>
              Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Filtros",

  props: {
    P_titulo: {
      type: Boolean,
      default: false,
    },
    P_status: {
      type: Boolean,
      default: false,
    },
    P_statusArray: {
      type: Array,
    },
    P_dataRange: {
      type: Boolean,
      default: false,
    },
    P_prioridade: {
      type: Boolean,
      default: false,
    },
    P_clienteServico: {
      type: Boolean,
      default: false,
    },
    P_dataFrom: {
      type: Boolean,
      default: false,
    },
    P_dataTo: {
      type: Boolean,
      default: false,
    },
    P_clientes: {
      type: Boolean,
      default: false,
    },
    P_colaborador: {
      type: Boolean,
      default: false,
    },
    P_dataColuna: {
      type: Boolean,
      default: false,
    },
    P_clientesColaborador: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentKey: 0,
    };
  },

  components: {
    Titulo: () => import("./components/Titulo.vue"),
    Status: () => import("./components/Status.vue"),
    DataRange: () => import("./components/DataRange.vue"),
    Prioridade: () => import("./components/Prioridade.vue"),
    ClienteServico: () => import("./components/ClienteServico.vue"),
    DataFrom: () => import("./components/DataFrom.vue"),
    DataTo: () => import("./components/DataTo.vue"),
    Clientes: () => import("./components/Clientes.vue"),
    Colaboradores: () => import("./components/Colaboradores.vue"),
    ClientesColaborador: () => import("./components/ClientesColaborador.vue"),
    SelectedInicioEntrega: () => import("./components/SelectedInicioEntrega.vue"),
  },

  computed: {
    ...mapState("Filtros", {
      filtros: (state) => state.filtros,
    }),
    queryEmpty() {
      let verify = false;
      if (JSON.stringify(this.$route.query) === null) {
        verify = true;
      } else {
        verify = false;
      }
      return verify;
    },
    valid() {
      let value = false;
      for (let key in this.filtros) {
        if (this.filtros[key] || this.filtros[key] === 0) {
          value = true;
        }
      }
      return value;
    },
    route() {
      return this.$route;
    },
  },

  methods: {
    ...mapActions("Filtros", {
      reset: "reset",
    }),
    reloadComponent() {
      this.componentKey += 1;
    },

    resetQuery() {
      this.$router.replace({ query: null }).catch(() => {});
      this.reset();
      this.reloadComponent();
    },

    filtrar() {
      let filtro = {};
      for (let key in this.filtros) {
        if (this.filtros[key] || this.filtros[key] === 0) {
          filtro[key] = this.filtros[key];
        }
      }
      if (JSON.stringify(this.$route.query) === JSON.stringify(filtro)) return;

      this.$router.push({ query: filtro });
    },
    getAll() {
      if (JSON.stringify(this.$route.query) === JSON.stringify({ load: "all" })) return;
      this.$router.push({ query: { load: "all" } });
    },
  },

  mounted() {
    if (!this.queryEmpty) {
      this.panel = 0;
    }
  },
};
</script>

<style></style>
